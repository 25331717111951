<template>
  <div class="applyFlexCenterWithHeight ">
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form class="align-center"
              @submit.prevent="handleSubmit(changePassword)" id="changePassword"
      >
        <h3 class="tw-font-black tw-text-lg tw-mb-9">Create New Password</h3>
        <div v-if="passwordError">
          <span class="err text-xl-center" style="color: red">{{ passwordError }}</span>
        </div>
        <ValidationProvider
            name="password"
            rules="required|min:6"
            v-slot="{ errors }"
        >
          <p>New Password</p>
          <span class="err">{{ errors[0] }}</span>
          <v-text-field
              :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="() => (value = !value)"
              :type="value ? 'password' : 'text'"
              placeholder="Enter new password"
              solo
              hide-details
              v-model="newPassword"
              id="newPassword"
          ></v-text-field>
        </ValidationProvider>
        <ValidationProvider name="check" rules="required|min:6">
          <span class="err">{{ passwordError }}</span>
          <p class="tw-mt-8">Confirm Password</p>
          <v-text-field
              :append-icon="value1 ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="() => (value1 = !value1)"
              :type="value1 ? 'password' : 'text'"
              label="Confirm new password"
              solo
              hide-details
              v-model="confirmPassword"
              id="confirmPassword"
          ></v-text-field>
        </ValidationProvider>
        <BaseButton :loading="loading" depressed id="confirmPassword" class="tw-mt-10"
                    button-text="Create Password" width="361"/>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import BaseButton from "../../../UIComponents/BaseButton";
import {changePassword} from "../../../../services/api/AuthApiService";
import {removeUnwantedProperties} from "../../../../services/utils";

export default {
  name: "ChangePassword",
  components: {BaseButton},
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      showLoader: false,
      value: true,
      value1: true,
      passwordError: "",
      loading: false
    };
  },
  methods: {
    changePassword() {
      this.loading = true;
      if (!this.validatePassword()) {
        setTimeout(() => {
          this.passwordError = "";
        }, 3000);
        return this.loading = false;
      } else {
        Object.assign(this.$route.query, {password: this.newPassword});
        changePassword(removeUnwantedProperties(this.$route.query, ['reset-password'])).then(
            response => {
              this.$displaySnackbar({
                message: response.data.detail,
                success: true
              })
              this.loading = false;
            },
            error => {
              this.$displaySnackbar({
                message: error.response.data.detail,
                success: false
              })
              setTimeout(() => {
                this.passwordError = "";
              }, 3000);
              this.loading = false
            })
      }
    },
    validatePassword() {
      if (this.newPassword === this.confirmPassword) {
        return true;
      } else {
        this.passwordError = "Password mismatch";
        return false;
      }
    }
  }
}
</script>
<style scoped>

</style>
