<template>
  <layout>
    <template v-slot:header>
      <Header>
        <template v-slot:right-header-component>
          <p class="pt-5 loginFont">Already have an account ? <span class="pl-3 login" style="cursor: pointer" @click="goto">Login</span></p>
        </template>
      </Header>
    </template>

    <template  v-slot:main-content>
      <div class="filheight">
        <ChangePassword/>
      </div>
    </template>
  </layout>
</template>

<script>
import ChangePassword from "../../components/onboarding/components/resetPassword/ChangePassword";
import Header from "../../components/onboarding/reuseables/Header";
import layout from "../../components/onboarding/layouts/Onboardlayout"
export default {
  name: "Change",
  components: {Header, ChangePassword, layout},
  methods:{
    goto(){
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.loginFont{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #8692A6 !important;
}
.filheight{
  height: calc(100vh - 70px);
}
.login{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: right;

  color: #008EEF;

}
</style>